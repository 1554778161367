@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400|Playfair+Display);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, button {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #263238;
  color: var(--black, black);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

p, a, button {
  font-family: 'Open Sans', sans-serif;
}

:root {
  --blue: #00CCFF;
  --black: #263238;
  --white: #F8F9F9;
}
/*--------------------*/
/*        Main        */
/*--------------------*/
.highlight {
    color: deepskyblue;
    color: var(--blue, deepskyblue);
    font-size: 0.8rem;
    letter-spacing: 0.225em;
    text-transform: uppercase;
}

.showcase {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding: 1.2rem;
}

.fadein {
    -webkit-animation: fadein .3s ease-in;
            animation: fadein .3s ease-in;
}

.gray-angled {
    background-color: whitesmoke;
    background-color: var(--white, whitesmoke);
    position: relative;
    z-index: 1;
    margin-top: 2.4rem;
    margin-bottom: 2.4rem;
}
    .gray-angled:before,
    .gray-angled:after {
        background: inherit;
        content: '';
        display: block;
        height: 50%;
        left: 0;
        right: 0;
        position: absolute;
        z-index: -1;
    }
    .gray-angled:before {
        top: 0;
        -webkit-transform: skewY(1.5deg);
                transform: skewY(1.5deg);
        -webkit-transform-origin: 100% 0;
                transform-origin: 100% 0;
    }
    .gray-angled:after {
        bottom: 0;
        -webkit-transform: skewY(-1.5deg);
                transform: skewY(-1.5deg);
        -webkit-transform-origin: 100%;
                transform-origin: 100%;
    }

.main-header {
    text-align: center;
    padding: 1.2rem;
    color: black;
    color: var(--black, black);
}
.sub-header {
    padding: 1.8rem;
    padding-bottom: 1.2rem;
    color: black;
    color: var(--black, black);
}

/* Modal fades in when opens */ 
.ReactModal__Overlay,
.ReactModal__Overlay--after-open {
    -webkit-animation: fadein .3s ease-in;
            animation: fadein .3s ease-in;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}
/* Ensures background does not scroll */
.ReactModal__Body--open {
    overflow: hidden;
}

/*--------------------*/
/*     Animations     */
/*--------------------*/
@-webkit-keyframes popup {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes popup {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

/*--------------------*/
/*    PAGE : About    */ 
/*--------------------*/
.About main {
    display: -webkit-flex;
    display: flex;
}
.About main > div {
    width: 25%;
    text-align: center;
    padding-bottom: 16px;
}
.About main > summary {
    width: 75%;
    line-height: 1.6rem;
}
.About main summary > p {
    padding-bottom: 16px;
}
.About main,
.About section {
    padding: 0 2.4rem;
    margin: 2.4rem auto;
}

/*--------------------*/
/*    PAGE : Home    */ 
/*--------------------*/
.Home main {
    padding: 0 2.4rem;
    margin: 2.4rem auto;
    line-height: 1.6;
}
.Home main h1 {
    font-size: 6.6rem;
    /* padding-bottom: 16px; */
}
.Home main h1.fadein-2 {
    -webkit-animation: fadein 1.6s ease-in-out;
            animation: fadein 1.6s ease-in-out;
}
.Home main h1.fadein-3 {
    -webkit-animation: fadein 3s ease-in-out;
            animation: fadein 3s ease-in-out;
}

/*--------------------*/
/*  ELEMENT : Anchor  */
/*--------------------*/
.Anchor {
    display: inline-block;
    text-decoration: none;
    color: black;
    color: var(--black, black);
    border-bottom: 1px dotted deepskyblue;
    border-bottom: 1px dotted var(--blue, deepskyblue);
    /* padding: 0.2rem 0; */
    transition: 0.2s all;
}

.Anchor:hover {
    border-bottom: 1px dotted rgba(255, 255, 255, 0);
}

/*--------------------*/
/*  ELEMENT : Button  */
/*--------------------*/
.Button {
    background: none;
    background-color: white;
    outline: none;
    border: none;
    border: 1px solid black;
    border: 1px solid var(--black, black);
    color: black;
    color: var(--black, black);
    padding: 0.4rem 1rem;
    font-size: 0.8rem;
    letter-spacing: 0.225em;
    text-transform: uppercase;
    transition: 0.2s all;
}

.Button:hover {
    border: 1px solid deepskyblue;
    border: 1px solid var(--blue, deepskyblue);
}

/*--------------------*/
/*   ELEMENT : Link   */ 
/*--------------------*/
.Link {
    text-decoration: none;
    color: black;
    color: var(--black, black);
    display: inline-block;
    font-size: 0.8rem;
    letter-spacing: 0.225em;
    text-transform: uppercase;
    margin: 0.6rem;
}

/*--------------------*/
/* ELEMENT :  NavBar  */ 
/*--------------------*/
.NavBar {
    border-bottom: 1px solid whitesmoke;
    border-bottom: 1px solid var(--white, whitesmoke);
    box-shadow: 0 0 24px whitesmoke;
    box-shadow: 0 0 24px var(--white, whitesmoke);
    padding: 1.2rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.NavBar .logo {
    width: 26px;
    height: auto;
}

.NavBar .nav-links {
    display: -webkit-flex;
    display: flex;
}

/*------------------------*/
/* ELEMENT : ProjectImage */ 
/*------------------------*/
.ProjectImage {
    width: calc(33% - 1.2rem);
    position: relative;
    margin: 0.6rem;
}
.ProjectImage:hover .overlay {
    top: 0;
    height: 100%;
    cursor: pointer;
}
.ProjectImage img {
    width: 100%;
    transition: 0.3s ease;
}
.overlay {
    background-color: white;
    opacity: 0.95;
    position: absolute;
    top: 100%;
    overflow: hidden;
    height: 0;
    left: 0;
    right: 0;
    transition: 0.3s ease;
}
.text {
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;
    line-height: 1.6;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

/*------------------------*/
/* ELEMENT : ProjectModal */ 
/*------------------------*/
.ProjectModal {
    width: 100%;
    height: 100%;
}

.ProjectModal .close {
    width: 48px;
    height: 48px;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.ProjectModal .close:hover {
    opacity: 1;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.ProjectModal header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.ProjectModal main {
    display: -webkit-flex;
    display: flex;
    position: relative;
    top: 14%;
}

.ProjectModal main aside {
    width: 50%;
}

.ProjectModal main aside img {
    width: 100%;
    height: auto;
}

.ProjectModal main summary {
    width: 50%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    line-height: 1.6;
    padding: 20px;
}

.ProjectModal main p {
    padding-bottom: 16px;
}

.ProjectModal main .tagline {
    font-weight: 500;
}

/*--------------------*/
/*  ELEMENT : Pixel   */ 
/*--------------------*/
.Pixel {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: calc(20% - 1.2rem);
    height: auto;
    margin: 0.6rem;
}

.Pixel img {
    height: 80%;
}

.Pixel p {
    font-size: 0.8rem;
    letter-spacing: 0.225em;
    text-transform: uppercase;
}

/*--------------------*/
/*  ELEMENT : Skills  */ 
/*--------------------*/
.Skills {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    padding: 1.2rem;
}

.Skills .skill-box {
    width: 100px;
    height: auto;
    margin: 0.6rem;
}

/*--------------------*/
/*  ELEMENT : Social  */ 
/*--------------------*/
.Social {
    padding: 1.2rem;
}
.Social a {
    text-decoration: none;
    color: black;
    color: var(--black, black);
    display: inline-block;
    margin: 0.6rem;
}

/*--------------------*/
/*    Media Queries   */
/*--------------------*/
@media screen and (min-width: 2000px) {
    .Home main {
        line-height: 2.2;
    }
    .Home main h1 {
        font-size: 10.6rem;
    }
    .ProjectImage {
        width: calc(25% - 1.2rem);
    }
}

@media screen and (max-width: 900px) {
    .Home main h1 {
        font-size: 4.6rem;
    }
    .Pixel {
        width: calc(25% - 1.2rem);
    }
    .ProjectImage {
        width: calc(50% - 1.2rem);
    }
    .ProjectModal .close {
        width: 32px;
        height: 32px;
    }
    .ProjectModal main {
        -webkit-flex-direction: column;
                flex-direction: column;
        top: auto;
        top: initial;
    }
    .ProjectModal main aside {
        width: 100%;
    }
    .ProjectModal main summary {
        width: calc(100% - 40px);
    }
}

@media screen and (max-width: 650px) {
    .Home main {
        margin: 0 auto;
        padding: 1.2rem 2.4rem;
    }
    .Home main h1 {
        font-size: 3.6rem;
    }
    .Pixel {
        width: calc(50% - 1.2rem);
    }
    .ProjectImage {
        width: calc(100% - 1.2rem);
    }
    .Skills {
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        -webkit-justify-content: space-between;
                justify-content: space-between;
    }
    .About main {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
    .About main > div,
    .About main > summary {
        width: 100%;
    }
    .About main {
        margin-top: 0;
    }
}
