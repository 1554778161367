@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400|Playfair+Display');

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

h1, h2, h3, h4, h5, h6, p, a, button {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: var(--black, black);
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
}

p, a, button {
  font-family: 'Open Sans', sans-serif;
}

:root {
  --blue: #00CCFF;
  --black: #263238;
  --white: #F8F9F9;
}